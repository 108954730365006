import $ from 'jquery';
import customSelect from './lib/CustomSelect.js';
import HeaderHide from './lib/header.js';

$(document).click(function (event) {
    if (!$(event.target).closest('#header__search--button,.header__search,.header__search-result').length) {
        $('body').find('.header__search-result').hide();
        $('body').find('.header__search').removeClass('header__search--open');
        _enable_scroll('body');
    }
    if (!$(event.target).closest('#header__bar--button,.menu-modal').length) {
        $('body').find('.menu-modal').slideUp('fast');
    }
});
function _disable_scroll(tag){
    if (_isSp()) {
        setTimeout(() => {
            $(tag).css('overflow-y','hidden');
            $(tag).css('position','fixed');
        }, 300);
    }
}
function _enable_scroll(tag){
    if (_isSp()) {
        setTimeout(() => {
            $(tag).css('overflow-y','auto');
            $(tag).css('position','initial');
        }, 300);
    }
}
function _isSp() {
    if (window.innerWidth < 768) {
        return true
    }
    return false
}
$('#header__bar--button').click(function () {
    _disable_scroll('body');
    $('.menu-modal').slideDown('fast');
    return false;
});

$('#header__search--button').click(function (e) {
    e.preventDefault();
    if($(this).parent().find('.header__search--open').length > 0 ){
        if($(this).parent().find('.header__search').val() !== ''){
            window.location.href = '/results/' + $(this).parent().find('.header__search').val();
        }
    }else{
        if ($(window).width() > 768) {
            $('.header__search').addClass('header__search--open');
        } else {
            _disable_scroll('body');
            $('.search-modal').slideDown('fast');
        }
    }
    return false;
});

$('#header__search--buttonSP').click(function (e) {
    e.preventDefault();
    if($(this).parent().find('.header__search--sp').val() !== ''){
        window.location.href = '/results/' + $(this).parent().find('.header__search--sp').val();
    }
    return false;
});

$('.header__search').keyup(function (e) {
    $('.header__search-result').show();
    if(e.keyCode == 13){
        if($(this).val() !== ''){
            window.location.href = '/results/' + $(this).val();
        }
    }
    return false;
});

$('.header__search--sp').keyup(function (e) {
    if(e.keyCode == 13){
        if($(this).val() !== ''){
            window.location.href = '/results/' + $(this).val();
        }
    }
    return false;
});


$('.menu-modal__close').click(function () {
    $('.menu-modal').slideUp('fast');
    _enable_scroll('body');
    return false;
});
$('a[href="#mailmag"]').click(function(event) {
    $('.menu-modal').slideUp('fast');
    _enable_scroll('body');
    $('html,body').animate({
        scrollTop: $('#mailmag').offset().top
    }, 700);
    return false;
});
$('.search-modal__close').click(function () {
    $('.search-modal').slideUp('fast');
    _enable_scroll('body');
    return false;
});
$('.header__search-result li a').click(function (e) {
   e.preventDefault();
   $('.header__search').val($(this).text());
});

$('.back-to-top').click(function () {
    $('html, body').animate({ scrollTop: 0 }, 700);
    return false;
});

$('.post-catalog .post-catalog__list li a').click(function (e) {
    e.preventDefault();
    var target = $(this).attr('href');
    $('html, body').animate({ scrollTop: $(target).offset().top }, 700);
    return false;
});

$('.post-catalog .post-catalog__action span').click(function (e) {
    if($(this).closest(".js-open").length > 0){
        $('.js-open').removeClass('js-open').addClass('js-close');
    }else{
        $('.js-close').removeClass('js-close').addClass('js-open');
    }

    return false;
});

$(window).on('load resize',function () {
    $('.post-catalog .post-catalog__list').each(function () {
        var height = 0;
        $(this).find("li.post-catalog__item").each(function () {
            height = height + $(this).outerHeight(true);
        });
        $(this).css('max-height',height);
    });
})
