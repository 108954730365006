import $ from 'jquery';

var list = [
    $('.js-scroll-hide-header'),
    $('.post-follow')
]

var lastScrollTop = 0;
var delay = 0;
$(window).scroll(function (event) {
    var currentPos = $(this).scrollTop();

    if (currentPos > 160) { // large than header height
        if (currentPos < lastScrollTop + delay) {
            // upscroll code

            list.map(function (item) {
                item.removeClass('scroll-effect')
            })

        } else {
            // downscroll code

            list.map(function (item) {
                item.addClass('scroll-effect')
            })
        }
    }
    lastScrollTop = currentPos;
});